import { render, staticRenderFns } from "./ResetPasswordFormModal.vue?vue&type=template&id=73ed4350&"
import script from "./ResetPasswordFormModal.vue?vue&type=script&lang=js&"
export * from "./ResetPasswordFormModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports